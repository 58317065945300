import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Panel } from '@sites/data-hmm/hmm-ninja';
import { panelServiceActions } from './panel.actions';

export const PanelKey = 'panel';

export interface PanelEntityState extends EntityState<Panel> {
  loading: boolean;
  error?: Error;
}

export const panelAdapter: EntityAdapter<Panel> = createEntityAdapter<Panel>();

export const initialPanelState: PanelEntityState = panelAdapter.getInitialState(
  {
    loading: false,
    error: undefined,
  }
);

export const panelReducer = createReducer(
  initialPanelState,
  on(panelServiceActions.listRefresh, (state) => {
    return panelAdapter.removeAll({
      ...state,
      loading: true,
      error: undefined,
    });
  }),
  on(panelServiceActions.listSuccess, (state, { panels }) => {
    return panelAdapter.setAll(panels, {
      ...state,
      loading: false,
      error: undefined,
    });
  }),
  on(panelServiceActions.listFailure, (state, { error }) => {
    return panelAdapter.removeAll({
      ...state,
      loading: false,
      error,
    });
  })
);
