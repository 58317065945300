import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PanelEntityState, PanelKey, panelAdapter } from './panel.reducer';

const selectPanelState = createFeatureSelector<PanelEntityState>(PanelKey);

export const {
  selectAll: selectAllPanels,
  selectEntities: selectPanelEntities,
} = panelAdapter.getSelectors(selectPanelState);

export const selectPanelLoading = createSelector(
  selectPanelState,
  (state) => state.loading
);

export const selectPanelError = createSelector(
  selectPanelState,
  (state) => state.error
);
