import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Panel } from '@sites/data-hmm/hmm-ninja';

export const panelServiceActions = createActionGroup({
  source: 'DashboardStore PanelService',
  events: {
    List: emptyProps(),
    ListRefresh: emptyProps(),
    ListSuccess: props<{ panels: Panel[] }>(),
    ListFailure: props<{ error: Error }>(),
  },
});
